import AppPropertiesContextProvider from "@/components/AppProperties/AppPropertiesContext";
import PageLayoutContexts from "@/components/PageLayout/PageLayoutContexts";
import { AsyncTasksContextProvider } from "@/modules/async-tasks/AsyncTasksContext";
import appcues from "@/utils/appcues";
import { Forms } from "@pricefx/unity-components";
import { enableMapSet } from "immer";
import moment from "moment";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router5";
import "./assets/style.css";
import { ContentLayoutProvider } from "./components/ContentLayout/ContentLayoutProvider.component";
import { DicContext } from "./components/Dic/Dic.context";
import { useDic } from "./components/Dic/useDic.hook";
import { ErrorBoundary } from "./components/Error";
import SSEContextProvider from "./components/ServerSideEvents/SSEContext";
// dependency injection
import { getRawDic } from "./dic/dic";
import { registerServices } from "./dic/registerServices.dic";
import { RouteDataContextProvider } from "./mixpanel/RouteDataContextProvider";
import { TrackingContextProvider } from "./mixpanel/TrackingContextProvider";
import { ConfirmModalProvider } from "./modules/modal";
import { CustomModalProvider } from "./modules/modal/CustomModal";
// locations
import { LocationRoutes, PreventTransitionProvider } from "./modules/router";
import UserContextComponent from "./security/UserContext";
import UserSecurityContentContext from "./security/UserSecurityContentContext";
import { IntlProvider, t } from "./translations";
import { L18NProvider } from "./translations/unityTranslations";
// Views
import { UpdateChecker } from "@/components/UpdateChecker/UpdateChecker.component";
import { logger } from "@/modules/logger";
import { AuthenticationService } from "@/services/auth.service";
import { createRoot } from "react-dom/client";
import { setTitle } from "./utils/document";
import { FullPageContainer } from "./views/Pages/FullPage.container";
import { ApprovalWorkflowsEnabledProvider } from "./components/WorkFlow/ApprovalWorkflowsEnabledProvider";

if (process.env.NODE_ENV === "development") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React);
}

logger.debug({
    logGroupKey: ["SCAFFOLD", "root"],
    color: "deepskyblue",
    data: { href: window.location.href },
});

const { RecoilRoot } = Forms;

moment.locale("en");

function configureImmer() {
    enableMapSet();
}

export const App = () => {
    const { locations, authenticationService } = useDic();

    useEffect(() => {
        setTitle(t("app-title"));
    });

    logger.debug({
        logGroupKey: ["SCAFFOLD", "App", "rndr"],
        color: "deepskyblue",
        data: {
            isLoggedIn: authenticationService.isLoggedIn(),
            locations,
        },
    });

    if (authenticationService.isLoggedIn()) {
        return (
            <>
                <UpdateChecker />
                <ConfirmModalProvider>
                    <CustomModalProvider>
                        <PreventTransitionProvider>
                            <FullPageContainer>
                                <ApprovalWorkflowsEnabledProvider>
                                    <LocationRoutes locations={locations} />
                                </ApprovalWorkflowsEnabledProvider>
                            </FullPageContainer>
                        </PreventTransitionProvider>
                    </CustomModalProvider>
                </ConfirmModalProvider>
            </>
        );
    }
    return <LocationRoutes locations={locations} />;
};

function run({ authenticationService }) {
    configureImmer();
    const dic = getRawDic();

    registerServices(dic, { authenticationService });

    const { store, routerService } = dic.cradle;

    routerService.subscribe(() => {
        logger.debug({
            logGroupKey: ["SCAFFOLD", "run", "routerService.subscribe"],
            color: "deepskyblue",
            data: { root, href: window.location.href },
        });
        appcues.page();
    });

    const root = createRoot(document.getElementById("root"));

    routerService.start(() => {
        logger.debug({
            logGroupKey: ["SCAFFOLD", "run", "routerService.start"],
            color: "deepskyblue",
            data: { root },
        });
        root.render(
            <ErrorBoundary>
                <RecoilRoot>
                    <DicContext.Provider value={dic.cradle}>
                        <IntlProvider>
                            <L18NProvider>
                                <Provider store={store}>
                                    <RouterProvider router={routerService}>
                                        <AppPropertiesContextProvider>
                                            <UserContextComponent>
                                                <RouteDataContextProvider>
                                                    <TrackingContextProvider>
                                                        <UserSecurityContentContext>
                                                            <ContentLayoutProvider>
                                                                <PageLayoutContexts>
                                                                    <SSEContextProvider>
                                                                        <AsyncTasksContextProvider>
                                                                            <App />
                                                                        </AsyncTasksContextProvider>
                                                                    </SSEContextProvider>
                                                                </PageLayoutContexts>
                                                            </ContentLayoutProvider>
                                                        </UserSecurityContentContext>
                                                    </TrackingContextProvider>
                                                </RouteDataContextProvider>
                                            </UserContextComponent>
                                        </AppPropertiesContextProvider>
                                    </RouterProvider>
                                </Provider>
                            </L18NProvider>
                        </IntlProvider>
                    </DicContext.Provider>
                </RecoilRoot>
            </ErrorBoundary>,
        );
    });
}

(async () => {
    try {
        const authenticationService = new AuthenticationService();
        await authenticationService.gainToken();
        run({ authenticationService });
    } catch (error) {
        logger.debug({
            logGroupKey: ["SCAFFOLD", "run", "error"],
            color: "deepskyblue",
            data: {
                error,
                message: error.message,
            },
        });
    }
})();
