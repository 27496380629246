import { Menu } from "@/components/DesignSystem/Menu";
import { useDic } from "@/components/Dic/useDic.hook";
import { InstanceSelect } from "@/components/InstanceSelect/InstanceSelect.component";
import { IM_VERSION_BEFORE_CERTIFICATES_SUPPORT } from "@/components/Integrations/constants";
import { ISVConnectionsSelect } from "@/components/ISVConnections/ISVConnectionsSelect.component";
import { mapPartitionsToOptions } from "@/components/Marketplace/helpers";
import {
    LocationMenuContainer,
    LocationMenuItem,
    LocationSubMenu,
} from "@/components/Menu";
import { PartitionSelect } from "@/components/PartitionSelect/PartitionSelect.component";
import { useRouteAccountInstances } from "@/mixpanel/hooks/useRouteAccountInstances.hook";
import { useRouteAccountIsvConnections } from "@/mixpanel/hooks/useRouteAccountIsvConnections.hook";
import { useRouteAccountPartitions } from "@/mixpanel/hooks/useRouteAccountPartitions.hook";
import { useMapLoadableMemoized } from "@/modules/loadable";
import { useLoadableHasValueChangedEffect } from "@/modules/loadable/useLoadableHasValueChangedEffect.hook";
import { logger } from "@/modules/logger";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { SecurityContext } from "@/security/authorization";
import { useFeatureFlag } from "@/security/hooks/useFeatureFlag.hook";
import { instancesServices } from "@/services/instance.service";
import {
    getDefaultIntegrationLocation,
    getDefaultPartitionLocation,
} from "@/utils/location/defaultLocation.utils";
import { isNewer } from "@/utils/versionUtils";
import head from "lodash/head";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useUserPreferencesQuery } from "../../../administrationApp/components/Profile/DefaultRoute.loadables";

export const AccountAppMenu = ({ accountId }) => {
    const {
        partitionId: routePartitionId,
        instanceId: routeInstanceId,
        isvConnectionId: routeIsvConnectionId,
    } = useAccountAppParams();
    const { locationRouterService, accountAppLocations } = useDic();
    const { accountPartitionsLoadable } = useRouteAccountPartitions();
    const { accountInstancesLoadable } = useRouteAccountInstances();
    const { accountIsvConnectionsLoadable } = useRouteAccountIsvConnections();
    const secureContext = useContext(SecurityContext);

    const [partitionId, setPartitionId] = useState(routePartitionId);
    const [instanceId, setInstanceId] = useState(routeInstanceId);
    const [isvConnectionId, setIsvConnectionId] =
        useState(routeIsvConnectionId);

    useEffect(() => {
        if (!isNaN(routePartitionId)) {
            setPartitionId(routePartitionId);
        }

        if (!isNaN(routeInstanceId)) {
            setInstanceId(routeInstanceId);
        }

        if (!isNaN(routeIsvConnectionId)) {
            setIsvConnectionId(routeIsvConnectionId);
        }
    }, [
        accountInstancesLoadable,
        partitionId,
        routeInstanceId,
        routeIsvConnectionId,
        routePartitionId,
    ]);

    // was selected partition before, select first one from switched account
    useLoadableHasValueChangedEffect(
        accountPartitionsLoadable,
        accountPartitions => {
            if (!accountPartitions.length) {
                setPartitionId();
                locationRouterService.navigate(
                    accountAppLocations.dashboardLocation,
                );
            } else if (!isNaN(partitionId) && isNaN(routePartitionId)) {
                const accountPartitionsIds = accountPartitionsLoadable
                    .valueMaybe()
                    .map(partition => partition.id);
                const firstAvailablePartition = head(accountPartitionsIds);

                if (firstAvailablePartition) {
                    setPartitionId(firstAvailablePartition);
                }
            } else {
                setPartitionId(routePartitionId);
            }
        },
        [partitionId],
    );

    // was selected integration before, select first one from switched account
    useLoadableHasValueChangedEffect(
        accountInstancesLoadable,
        accountInstances => {
            if (!accountInstances.length) {
                setInstanceId();
                locationRouterService.navigate(
                    accountAppLocations.dashboardLocation,
                );
            } else if (!isNaN(instanceId) && isNaN(routeInstanceId)) {
                const accountInstancesIds = accountInstancesLoadable
                    .valueMaybe()
                    .map(instance => instance.id);
                const firstAvailableInstance = head(accountInstancesIds);

                if (firstAvailableInstance) {
                    setInstanceId(firstAvailableInstance);
                }
            } else {
                setInstanceId(routeInstanceId);
            }
        },
        [instanceId],
    );

    // was selected isvConnection before, select first one from switched account
    useLoadableHasValueChangedEffect(
        accountIsvConnectionsLoadable,
        accountIsvConnections => {
            if (!accountIsvConnections.length) {
                setIsvConnectionId();
                locationRouterService.navigate(
                    accountAppLocations.dashboardLocation,
                );
            } else if (!isNaN(isvConnectionId) && isNaN(routeIsvConnectionId)) {
                const accountIsvConnectionsIds = accountIsvConnectionsLoadable
                    .valueMaybe()
                    .map(connection => connection.id);
                const firstAvailableConnection = head(accountIsvConnectionsIds);

                if (firstAvailableConnection) {
                    setIsvConnectionId(firstAvailableConnection);
                }
            } else {
                setIsvConnectionId(routeIsvConnectionId);
            }
        },
        [isvConnectionId],
    );

    const {
        accountActivityLogLocation,
        accountAdminLocation,
        accountAlertRulesLocation,
        accountAlertsLocation,
        accountApiTokenLocation,
        accountDeploymentLogLocation,
        accountGroupsLocation,
        accountLogsLocation,
        accountNotificationRulesLocation,
        accountReportsLocation,
        accountUsersLocation,
        assetsAccountAdminLocation,
        certificatesLocation,
        classesLocation,
        connectionsLocation,
        // contactManagementLocation,
        dashboardLocation,
        eventOrchestrationLocation,
        eventWorkflowsLocation,
        eventSchedulersLocation,
        eventLogsLocation,
        filtersLocation,
        instanceOverviewLocation,
        instancesLocation,
        integrationsLocation,
        mappersLocation,
        partitionFeatureFlagsLocation,
        calculationEnginesLocation,
        partitionOverviewLocation,
        partitionsLocation,
        // partitionUploadLogsLocation,
        // partitionUploadsLocation,
        isvConnectionsLocation,
        isvConnectionsOverviewLocation,
        isvConnectionsSettingsLocation,
        isvDataMappingListLocation,
        partitionDataManagementLocation,
        permissionAccountAdminLocation,
        resourcesLocation,
        settingsLocation,
        sftpServersLocation,
        ssoLocation,
        upgradesLocation,
    } = accountAppLocations;

    const userPreferencesQuery = useUserPreferencesQuery();
    const newLocationParams = {
        accountId,
        partitionId,
        instanceId,
        isvConnectionId,
    };

    const [instanceData, setInstanceData] = useState();

    useEffect(() => {
        setInstanceData(undefined);
        if (instanceId) {
            instancesServices.fetchInstance(instanceId).then(response => {
                setInstanceData(response.data);
            });
        }
    }, [instanceId]);

    // Only for manual instances
    const isInstanceConfigurable =
        instanceData && (instanceData?.configurableSupported ?? true);
    const isInstanceCreated = instanceData?.state === "CREATED";
    const isInCloudInstance = instanceData && (instanceData?.inCloud ?? false);

    const onInstanceChange = useCallback(
        instanceId => {
            locationRouterService.navigate(
                getDefaultIntegrationLocation(
                    instanceId,
                    secureContext,
                    accountAppLocations,
                ).location,
                {
                    instanceId,
                },
            );
        },
        [secureContext, accountAppLocations, locationRouterService],
    );

    const onPartitionsChange = useCallback(
        partitionId => {
            locationRouterService.navigate(
                getDefaultPartitionLocation(
                    partitionId,
                    secureContext,
                    accountAppLocations,
                ).location,
                {
                    partitionId,
                },
            );
        },
        [secureContext, accountAppLocations, locationRouterService],
    );

    const navigateToOverview = useCallback(
        isvConnectionId => {
            locationRouterService.navigate(isvConnectionsOverviewLocation, {
                accountId,
                isvConnectionId,
            });
        },
        [locationRouterService, isvConnectionsOverviewLocation, accountId],
    );

    const partitionsOptionsLoadable = useMapLoadableMemoized(
        accountPartitionsLoadable,
        mapPartitionsToOptions,
    );

    logger.debug({
        logGroupKey: ["COMPONENTS", "AccountAppMenu", "rndr"],
        color: "turquoise",
        data: {
            partitionsOptionsLoadable,
            accountPartitionsLoadable,
            accountInstancesLoadable,
        },
    });

    const isISVConnectionsEnabled = useFeatureFlag("isv-connection");

    return (
        <LocationMenuContainer mode="inline" eager condensed>
            <LocationMenuItem
                key={dashboardLocation.routeName}
                $location={dashboardLocation}
                $locationParams={newLocationParams}
            />
            <LocationSubMenu
                key={partitionsLocation.routeName}
                $location={partitionsLocation}
            >
                <Menu.ItemGroup
                    title={
                        <PartitionSelect
                            withoutBorder
                            partitionId={partitionId}
                            onChange={onPartitionsChange}
                            partitionsOptionsLoadable={
                                partitionsOptionsLoadable
                            }
                        />
                    }
                >
                    {partitionId && (
                        <LocationMenuItem
                            key={partitionOverviewLocation.routeName}
                            $location={partitionOverviewLocation}
                            $locationParams={newLocationParams}
                        />
                    )}
                    {/* {partitionId && (
                        <LocationMenuItem
                            key={partitionUploadsLocation.routeName}
                            $location={partitionUploadsLocation}
                            $locationParams={newLocationParams}
                        />
                    )}
                    {partitionId && (
                        <LocationMenuItem
                            key={partitionUploadLogsLocation.routeName}
                            $location={partitionUploadLogsLocation}
                            $locationParams={newLocationParams}
                        />
                    )} */}
                    {partitionId && (
                        <LocationMenuItem
                            key={partitionDataManagementLocation.routeName}
                            $location={partitionDataManagementLocation}
                            $locationParams={newLocationParams}
                        />
                    )}
                    {partitionId && (
                        <LocationMenuItem
                            key={partitionFeatureFlagsLocation.routeName}
                            $location={partitionFeatureFlagsLocation}
                            $locationParams={newLocationParams}
                        />
                    )}
                    {partitionId && (
                        <LocationMenuItem
                            key={calculationEnginesLocation.routeName}
                            $location={calculationEnginesLocation}
                            $locationParams={newLocationParams}
                        />
                    )}
                </Menu.ItemGroup>
            </LocationSubMenu>
            <LocationSubMenu
                key={instancesLocation.routeName}
                $location={instancesLocation}
            >
                <Menu.ItemGroup
                    title={
                        <InstanceSelect
                            availableInstancesLoadable={
                                accountInstancesLoadable
                            }
                            withoutBorder
                            instanceId={instanceId}
                            onChange={onInstanceChange}
                        />
                    }
                >
                    {isInstanceCreated && (
                        <LocationMenuItem
                            key={instanceOverviewLocation.routeName}
                            $location={instanceOverviewLocation}
                            $locationParams={newLocationParams}
                        />
                    )}
                    {isInstanceCreated && (
                        <LocationMenuItem
                            key={integrationsLocation.routeName}
                            $location={integrationsLocation}
                            $locationParams={newLocationParams}
                        />
                    )}
                    {isInstanceCreated && isInstanceConfigurable && (
                        <LocationMenuItem
                            key={mappersLocation.routeName}
                            $location={mappersLocation}
                            $locationParams={newLocationParams}
                        />
                    )}
                    {isInstanceCreated && isInstanceConfigurable && (
                        <LocationMenuItem
                            key={filtersLocation.routeName}
                            $location={filtersLocation}
                            $locationParams={newLocationParams}
                        />
                    )}
                    {isInstanceCreated && isInstanceConfigurable && (
                        <LocationMenuItem
                            key={connectionsLocation.routeName}
                            $location={connectionsLocation}
                            $locationParams={newLocationParams}
                        />
                    )}
                    {isInstanceCreated &&
                        isInstanceConfigurable &&
                        isNewer(
                            instanceData?.imVersion,
                            IM_VERSION_BEFORE_CERTIFICATES_SUPPORT,
                        ) && (
                            <LocationMenuItem
                                key={certificatesLocation.routeName}
                                $location={certificatesLocation}
                                $locationParams={newLocationParams}
                            />
                        )}
                    {isInstanceCreated && isInCloudInstance && (
                        <LocationMenuItem
                            key={resourcesLocation.routeName}
                            $location={resourcesLocation}
                            $locationParams={newLocationParams}
                        />
                    )}
                    {isInstanceCreated &&
                        isInstanceConfigurable &&
                        isInCloudInstance && (
                            <LocationMenuItem
                                key={classesLocation.routeName}
                                $location={classesLocation}
                                $locationParams={newLocationParams}
                            />
                        )}
                    {isInstanceCreated &&
                        isInstanceConfigurable &&
                        isInCloudInstance && (
                            <LocationMenuItem
                                key={sftpServersLocation.routeName}
                                $location={sftpServersLocation}
                                $locationParams={newLocationParams}
                            />
                        )}
                    {isInstanceCreated && (
                        <LocationMenuItem
                            key={settingsLocation.routeName}
                            $location={settingsLocation}
                            $locationParams={newLocationParams}
                        />
                    )}
                </Menu.ItemGroup>
            </LocationSubMenu>

            {userPreferencesQuery.loadable?.contents?.showAllAlertOptions ? (
                <LocationSubMenu
                    key={accountAlertsLocation.routeName}
                    $location={accountAlertsLocation}
                >
                    <LocationMenuItem
                        key={accountAlertRulesLocation.routeName}
                        $location={accountAlertRulesLocation}
                    />
                    <LocationMenuItem
                        key={accountNotificationRulesLocation.routeName}
                        $location={accountNotificationRulesLocation}
                    />
                </LocationSubMenu>
            ) : (
                <LocationSubMenu
                    key={accountAlertsLocation.routeName}
                    $location={accountAlertsLocation}
                >
                    <LocationMenuItem
                        key={accountNotificationRulesLocation.routeName}
                        $location={accountNotificationRulesLocation}
                    />
                </LocationSubMenu>
            )}
            <LocationMenuItem
                key={accountReportsLocation.routeName}
                $location={accountReportsLocation}
                $locationParams={newLocationParams}
            />
            <LocationSubMenu
                key={accountLogsLocation.routeName}
                $location={accountLogsLocation}
                $locationParams={newLocationParams}
            >
                <LocationMenuItem
                    key={accountActivityLogLocation.routeName}
                    $location={accountActivityLogLocation}
                    $locationParams={newLocationParams}
                />
                <LocationMenuItem
                    key={accountDeploymentLogLocation.routeName}
                    $location={accountDeploymentLogLocation}
                    $locationParams={newLocationParams}
                />
            </LocationSubMenu>
            <LocationSubMenu
                key={accountAdminLocation.routeName}
                $location={accountAdminLocation}
            >
                <LocationMenuItem
                    key={accountUsersLocation.routeName}
                    $location={accountUsersLocation}
                    $locationParams={newLocationParams}
                />
                <LocationMenuItem
                    key={accountGroupsLocation.routeName}
                    $location={accountGroupsLocation}
                    $locationParams={newLocationParams}
                />
                <LocationMenuItem
                    key={assetsAccountAdminLocation.routeName}
                    $location={assetsAccountAdminLocation}
                    $locationParams={newLocationParams}
                />
                <LocationMenuItem
                    key={permissionAccountAdminLocation.routeName}
                    $location={permissionAccountAdminLocation}
                    $locationParams={newLocationParams}
                />
            </LocationSubMenu>
            {/* Hidden @see https://pricefx.atlassian.net/browse/PFIM-8042 */}
            {/* <LocationMenuItem
                key={contactManagementLocation.routeName}
                $location={contactManagementLocation}
            /> */}
            <LocationMenuItem
                key={upgradesLocation.routeName}
                $location={upgradesLocation}
            />
            <LocationMenuItem
                key={ssoLocation.routeName}
                $location={ssoLocation}
            />
            <LocationSubMenu
                key={eventOrchestrationLocation.routeName}
                $location={eventOrchestrationLocation}
            >
                <LocationMenuItem
                    key={eventWorkflowsLocation.routeName}
                    $location={eventWorkflowsLocation}
                />
                <LocationMenuItem
                    key={eventSchedulersLocation.routeName}
                    $location={eventSchedulersLocation}
                />
                <LocationMenuItem
                    key={eventLogsLocation.routeName}
                    $location={eventLogsLocation}
                />
            </LocationSubMenu>

            {isISVConnectionsEnabled ? (
                <LocationSubMenu
                    key={isvConnectionsLocation.routeName}
                    $location={isvConnectionsLocation}
                >
                    <Menu.ItemGroup
                        title={
                            <ISVConnectionsSelect
                                accountId={accountId}
                                withoutBorder
                                isvConnectionId={isvConnectionId}
                                onChange={navigateToOverview}
                            />
                        }
                    >
                        {isvConnectionId && (
                            <LocationMenuItem
                                key={isvConnectionsOverviewLocation.routeName}
                                $location={isvConnectionsOverviewLocation}
                                $locationParams={newLocationParams}
                            />
                        )}
                        {isvConnectionId && (
                            <LocationMenuItem
                                key={isvDataMappingListLocation.routeName}
                                $location={isvDataMappingListLocation}
                                $locationParams={newLocationParams}
                            />
                        )}
                        {isvConnectionId && (
                            <LocationMenuItem
                                key={isvConnectionsSettingsLocation.routeName}
                                $location={isvConnectionsSettingsLocation}
                                $locationParams={newLocationParams}
                            />
                        )}
                    </Menu.ItemGroup>
                </LocationSubMenu>
            ) : null}
            <LocationMenuItem
                key={accountApiTokenLocation.routeName}
                $location={accountApiTokenLocation}
            />
        </LocationMenuContainer>
    );
};

AccountAppMenu.propTypes = {
    accountId: PropTypes.number.isRequired,
};
