import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { t } from "@/translations";
import {
    getErrorMessage,
    getErrorMessageFromError,
} from "@/utils/state/error.utils";
import { noop } from "lodash";

export const useConnectionsListQuery = ({ accountId, reloadToken }) => {
    const { isvConnectionService } = useDic();

    return useQueryLoadable(
        async () =>
            isNaN(accountId)
                ? pendingPromise()
                : isvConnectionService.listConnections(accountId),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [accountId, reloadToken],
    );
};
export const useRefreshSettingsMutation = () => {
    const { isvConnectionService } = useDic();

    return useMutationLoadableWithNotification(
        async connectionId =>
            isvConnectionService.refreshSettings(connectionId),
        [],
        ({ name }) =>
            t("isv-connections.steps.vendor.resend-to-partition.success"),
        getErrorMessage,
    );
};
export const useDeleteConnectionMutation = ({ afterSuccess = noop }) => {
    const { isvConnectionService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            isvConnectionService.deleteConnection(record.id).then(res => {
                afterSuccessCurrent();
                return res;
            }),
        [afterSuccessCurrent],
        ({ name }) =>
            t("general.message.deleted", { type: "connection", name }),
        getErrorMessage,
    );
};
export const useCreateConnectionMutation = ({ afterSuccess = noop }) => {
    const { isvConnectionService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async connection =>
            isvConnectionService.createConnection(connection).then(res => {
                afterSuccessCurrent(res);
                return res;
            }),
        [afterSuccessCurrent],
        ({ name }) =>
            t("general.message.created", { type: "connection", name }),
        getErrorMessage,
    );
};
export const useEditConnectionMutation = ({ connectionId, afterSuccess }) => {
    const { isvConnectionService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async connection =>
            isvConnectionService
                .editConnection(connectionId, connection)
                .then(res => {
                    afterSuccessCurrent(res);
                    return res;
                }),
        [afterSuccessCurrent],

        ({ name }) => t("general.saved", { type: "connection", name }),
        getErrorMessageFromError,
    );
};
